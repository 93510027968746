export const ApiURLConstants = {
  // user api
  VERIFY_USER_AFTER_LOGIN: '/users/info',

  // master data api
  GET_CREATE_MEDIA_PLAN_MASTER_DATA: '/user-master-data',
  GET_ALL_BRANDS: '/user-master-data/types?type=brand',
  MEDIA_PLAN_MASTER_DATA: '/master-data',
  GET_NGRP_FROM_MASTER_DATA: params =>
    `/master-data/ngrp-report?year=${params.year}&clusterTa=${params.clusterTA}&reportTa=${params.reportTA}&ngrp=${params.ngrp}`,
  GET_PLAN_BUDGET_FROM_MASTER_DATA: params =>
    `/master-data/planned-budget?year=${params.year}&targetAudience=${params.clusterTA}&month=${params.month}&duration=${params.duration}&grp=${params.grp}`,

  // file upload api
  UPLOAD_FILE: '/upload-file',

  // file download api
  GET_SIGN_URL: fileUrl => `/signed-url?fileName=${fileUrl}`,
  DOWNLOAD_FILE: params => `/download-file?fileName=${params.fileName}`,

  // media plan api
  LIST_MEDIA_PLAN: '/media-plans',
  GET_MEDIA_PLAN: '/media-plans',
  GET_MEDIA_PLAN_VALIDATION: params =>
    `/media-plans/${params.mediaPlanId}/validations?mediaPlanVersionId=${params.mediaPlanVersionId}`,
  CREATE_MEDIA_PLAN_ROW: params =>
    `/media-plans/media-plan-versions/${params.versionId}/rows?mediaPlanId=${params.mediaPlanId}`,
  CANCEL_MEDIA_PLAN_ROW: params => `/media-plans/media-plans-rows/${params.mediaPlanRowId}/cancel`,
  GET_MEDIA_PLAN_VERSION_LIST: params => `/versions/${params.mediaPlanId}`,
  GET_MEDIA_PLAN_VERSION_DETAILS: params =>
    `/version/${params.versionId}/details?mediaPlanId=${params.mediaPlanId}`,
  SAVE_AS_NEW_VERSION: params => `/media-plans/media-plans/${params.mediaPlanId}/versions`,
  CREATE_MEDIA_PLAN: '/media-plans',
  VALIDATE_MEDIA_PLAN: params => `/media-plans/${params.mediaPlanId}/validations?`,
  CREATE_MEDIA_PLAN_WITH_TEMPLATE: '/media-plans/upload-template',
  CHANGE_MEDIA_PLAN_STATUS: `/media-plans`,
  GET_MANAGER_LIST: params =>
    `/users/managers?${params?.marvelEntity ? `marvelEntity=${params.marvelEntity}` : ''}`,
  VIEW_APPROVAL_REQUEST: params => `/approval?versionId=${params.versionId}`,
  SEND_APPROVAL_FOR_MANAGER: '/approval',
  DOWNLOAD_MEDIA_PLAN: '/media-plans/download',
  DELETE_MEDIA_PLAN: '/media-plans',
  SAVE_GIVEN_BUDGET: '/media-plans/given-budget',

  // buying brief group api
  CREATE_BUYING_BRIEF_GROUP: '/buying-brief-groups',
  GET_BUYING_BRIEF_GROUP: params =>
    `/buying-brief-groups/${params.id}?mediaPlanId=${params.mediaPlanId}`,
  GET_LIST_BUYING_BRIEF_GROUP: params =>
    `/buying-brief-groups?mediaPlanVersionId=${params.versionId}${params.searchText ? `&searchText=${params.searchText}` : ''}${params.pageNo ? `&pageNo=${params.pageNo}` : ''}${params.pageSize ? `&pageSize=${params.pageSize}` : ''}`,
  UNLOCK_MEDIA_PLAN_ROWS: params => `/buying-brief-groups/${params.bbgId}/unlock-rows`,
  LOCK_MEDIA_PLAN_ROWS: params => `/buying-brief-groups/${params.bbgId}/lock-rows`,

  // comments api
  GET_ALL_COMMENTS: params =>
    `/comments?entityId=${params.entityId}&entityType=${params.entityType}`,
  ADD_COMMENTS: '/comments',
  EDIT_COMMENTS: params => `/comments/${params.commentId}`,
  DELETE_COMMENTS: params => `/comments/${params.commentId}`,

  // Internal Brief
  GENERATE_INTERNAL_BRIEF: `/generate-internal-brief`,
  SAVE_INTERNAL_BRIEF: id => `/internal-briefs/${id}`,
  VIEW_INTERNAL_BRIEF: id => `/internal-briefs/${id}`,
  SEND_INTERNAL_BRIEF_APPROVAL_REQUEST: id => `/internal-briefs/${id}/send-request`,
  VIEW_INTERNAL_BRIEF_REQUEST: id => `/internal-briefs/${id}/approval`,
  CHANGE_INTERNAL_BRIEF_STATUS: id => `/internal-briefs/${id}/approve-request`,

  //summary
  GET_ALL_SUMMARY: params =>
    `/media-plan-version-summary?type=${params.type}&filter=${params.filter}&mediaPlanId=${params.mediaPlanId}&mediaPlanVersionId=${params.mediaPlanVersionId}`,

  // po client
  SEARCH_PO_CLIENT: params => `/client-po?searchText=${params.searchText}`,
  GET_PO_CLIENT_DETAILS: id => `/client-po/${id}`,
  ASSIGN_PO_TO_BBG: poClient => `/client-po/${poClient}/assign-bbg`,
  GENERATE_CLIENT_PO_EXCEPTION: `/client-po-requests`,
  SEND_PO_EXCEPTION_TO_APPROVAL: `/update-client-po-requests`,
  CHANGE_PO_EXCEPTION_STATUS: id => `/client-po-requests/${id}/approve`,
  GET_CLIENT_PO_EXCEPTION_REQUEST: params => `/client-po-requests?bbgId=${params.bbgId}`,

  // vendor po
  LIST_VENDOR_PO: `/vendor-po`,
  LIST_VENDOR_PO_OLD: params =>
    `/vendor-po?pageSize=${params?.pageSize}&pageNo=${params?.pageNo}${params?.searchText ? `&searchText=${params.searchText}` : ''}${params?.filterBy ? `&filterBy=${params.filterBy}` : ''}${params?.sortBy ? `&sortBy=${params.sortBy}` : ''}${params?.sortType ? `&sortingField=${params.sortType}` : ''}`,

  // minder
  CREATE_BBG_ON_MINDER: '/minder/buying-brief-groups',
  UPDATE_BBG_ON_MINDER: '/minder/buying-brief-groups',
  CREATE_BB_ON_MINDER: '/minder/buying-briefs',
  UPDATE_BB_ON_MINDER: '/minder/buying-briefs',

  // buying brief
  CREATE_BUYING_BRIEF: '/buying-briefs',
  GET_BUYING_BRIEF: params =>
    `/buying-briefs?pageNo=${params.pageNo}&pageSize=${params.pageSize}&bbgId=${params.bbgId}`,
  CANCEL_RESTORE_BUYING_BRIEF: '/buying-briefs/cancel-restore',
  GET_BUYING_BRIEF_DETAILS: id => `/buying-briefs/${id}`,

  //project
  GET_PROJECT_LIST: '/projects/filter',
  CREATE_PROJECT: '/projects',
  UPDATE_PROJECT: id => `/projects/${id}`,
  GET_PROJECT_DETAILS: id => `/projects/${id}`,
  GET_PROJECT_TASK_DETAILS: id => `/tasks/${id}`,
  GET_PROJECT_SUB_TASK_DETAILS: id => `/subtasks/${id}`,
  GET_PROJECT_STATUS_LIST: type => `/projects/status?entityType=${type}`,
  UPDATE_PROJECT_REMARK: params =>
    `/projects/${params?.projectId}/tasks/${params?.projectTaskId}/remarks`,
  GET_PROJECT_MASTER_DATA: type => `/master-data/types?type=${type}`,
  GET_PROJECT_USER_MASTER_DATA: type => `/user-master-data/types?type=${type}`,
  GET_MASTER_DATA_BU: `/business-units`,
  PROJECT_APPROVAL: `/projects/approval`,
  UPDATE_PROJECT_STATUS: '/projects/status',
  SUBTASK_EDIT_REQUEST: subtaskId => `/subtasks/${subtaskId}/edit-requests`,
  CREATE_UPDATE_PROJECT_SUBMISSION: `/project-submissions`,
  CALCULATE_SUBTASK_DETAILS: `/send-calculation-table-request`,
  GET_PROJECT_SUBMISSION_DETAILS: id => `/projects/${id}/submissions`,
  TASK_APPROVAL_STATUS: `/tasks/approval`,

  //Notification
  SAVE_FCM_TOKEN: '/fcm-token',

  //Filter
  FETCH_FILTER_DATA: '/filters?entityName=',
};
